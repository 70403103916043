import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";

import { getUsername } from "utils/helper";
import { apiUrls, get, patch, post, put } from "utils/request";

export const initialState = {
  effectiveWorkHourCoal: {
    loading: false,
    records: [],
    totalRecords: "",
    error: "",
  },
  effectiveWorkHourCoalDetail: {
    productions: [],
    productionId: null,
    dayShift: null,
    nightShift: null,
    updatedAt: null,
    documentListEwh: [],
    statusLogList: [],
    disableSubmit: true,
  },
};

export const getEffectiveWorkHourCoal = createAsyncThunk(
  "effectiveWorkHourCoal/get",
  async (params) => {
    const response = await post(
      `${apiUrls.coalGetting}/effective-work-hour/filter`,
      {
        columns: [
          {
            data: "reportId",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: params?.reportId || "",
            searchable: true,
          },
          {
            data: "createdAt",
            orderable: true,
            search: {
              regex: true,
              value: "",
            },
            searchValue: params.date || "",
            searchable: true,
          },
          {
            data: "pit",
            orderable: true,
            search: {
              regex: params?.pit ? true : false,
              value: "",
            },
            searchValue: params?.pit || "",
            searchable: true,
          },
          {
            data: "contractorData",
            orderable: true,
            search: {
              regex: params?.contractorName ? true : false,
              value: "",
            },
            searchValue: params?.contractorName || "",
            searchable: true,
          },
          {
            data: "dataStatus",
            orderable: true,
            search: {
              regex: params?.status ? true : false,
              value: "",
            },
            searchValue: params?.status || "",
            searchable: true,
          },
        ],
        search: {
          regex: false,
          value: params.query || "",
        },
        draw: 1,
        length: params?.length || 50,
      }
    );
    return response?.data;
  }
);

export const getDetailEffectiveWorkHourCoal = createAsyncThunk(
  "effectiveWorkHourCoal/detail",
  async (params) => {
    const response = await get(
      `${apiUrls.coalGetting}/effective-work-hour/${params}`
    );
    return response?.data;
  }
);

export const downloadFile = createAsyncThunk(
  "download-file",
  async (params) => {
    const { idReport, filename } = params;
    const response = await get(
      `${apiUrls.coalGetting}/effective-work-hour/${idReport}/files/${filename}`
    );
    return response?.data;
  }
);

export const saveEffectiveWorkHourCoal = createAsyncThunk(
  "effectiveWorkHour/save",
  async (params) => {
    const { idReport, payload } = params;

    const response = await post(
      `${apiUrls.coalGetting}/effective-work-hour/production/${idReport}/save?userName=${getUsername()}`,
      payload
    );
    return response?.data;
  }
);
export const submitEffectiveWorkHourCoal = createAsyncThunk(
  "effectiveWorkHour/submit",
  async (params) => {
    const { idReport, payload } = params;
    console.log('😒 | file: reducer.js:138 | params:', params);
    // const response = await get(
    //   `${ apiUrls.coalGetting }/effective-work-hour/${params}/submit?userName=${getUsername()}`
    // );
    const response = await post(
      `${apiUrls.coalGetting}/effective-work-hour/production/${idReport}/submit?userName=${getUsername()}`,
      payload
    );
    return response?.data;
  }
);

export const updateEffectiveWorkHourStatus = createAsyncThunk(
  "effectiveWorkHour/updateStatus",
  async (params) => {
    const response = await patch(
      `${apiUrls.coalGetting}/effective-work-hour/production/${params.id}/data-status?dataStatus=${params.status}`
    );
    return response?.data;
  }
);

export const updateStatusEffectiveWorkHourCoal = createAsyncThunk(
  "effectiveWorkHour/admin-action-coal",
  async (params) => {
    const { id, adminAction, notes } = params

    const response = await put(
      `${apiUrls.coalGetting}/effective-work-hour/${id}/${adminAction}?userName=${getUsername()}`, notes
    );
    return response?.data;
  }
);

export const intervensiEwhCoal = createAsyncThunk(
  `effectiveWorkHour/intervensi-coal`,
  async (params) => {
    let response

    try {
      response = await post(`${apiUrls.coalGetting}/intervensi/user-role/ewh/id`, params);
      return response?.data;
    }
    catch (err) {
      return err
    }
  }
);

const effectiveWorkHourCoalSlice = createSlice({
  name: "COAL_GETTING",
  initialState,
  reducers: {
    getEffectiveWorkHourDetailCoal: (state, action) => {
      state.effectiveWorkHourCoalDetail = action.payload;
    },
    onChangeProductionCoal: (state, action) => {
      const { rowId, e } = action.payload;

      state.effectiveWorkHourCoalDetail.productions.map((production, idx) => {
        if (Number(rowId) === idx) {
          production[ e.target.name ] = Number(e.target.value);
        }
        return production;
      });
    },

    mapExcelData: (state, action) => {
      const { activeTab, dataExcel } = action.payload;
      const productions = current(state.effectiveWorkHourCoalDetail.productions);
      const filterProductions = productions?.filter((production) => production.status.job === activeTab);

      const result = filterProductions.map((item, index) => {
        const dataExcelItem = dataExcel[ index ];
        const { unit, volume, ewh, dailyProductivity } = dataExcelItem;
        return {
          ...item,
          id: {
            equipmentDetailId: unit
          },
          bcm: volume,
          ewh,
          productivity: dailyProductivity
        };
      });

      state.effectiveWorkHourCoalDetail.productions = result;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getEffectiveWorkHourCoal.pending, (state, action) => {
      state.loading = true;
      state.effectiveWorkHourCoal.loading = true;
    });
    builder.addCase(getEffectiveWorkHourCoal.fulfilled, (state, action) => {
      state.effectiveWorkHourCoal.loading = false;
      state.effectiveWorkHourCoal.totalRecords = action.payload.recordsTotal;
      state.effectiveWorkHourCoal.records = action.payload.list;
    });
    builder.addCase(getEffectiveWorkHourCoal.rejected, (state, action) => {
      state.loading = false;
      state.effectiveWorkHourCoal.loading = false;
      state.error = "Invalid get data";
    });

    builder.addCase(saveEffectiveWorkHourCoal.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(saveEffectiveWorkHourCoal.fulfilled, (state, action) => {
      state.response = action.payload;
    });
    builder.addCase(saveEffectiveWorkHourCoal.rejected, (state, action) => {
      state.loading = false;
      state.error = "Invalid get data";
    });

    builder.addCase(submitEffectiveWorkHourCoal.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(submitEffectiveWorkHourCoal.fulfilled, (state, action) => {
      state.response = action.payload;
    });
    builder.addCase(submitEffectiveWorkHourCoal.rejected, (state, action) => {
      state.loading = false;
      state.error = "Invalid get data";
    });

    builder.addCase(getDetailEffectiveWorkHourCoal.pending, (state, action) => {
      state.effectiveWorkHourCoalDetail.loading = true;
    });

    builder.addCase(
      getDetailEffectiveWorkHourCoal.fulfilled,
      (state, action) => {
        let productions = [];
        action.payload.coalGettingDetailResponseList.forEach((element) => {
          element?.production?.pitToRom?.forEach((production) => {
            productions.push({
              ...production,
              shift: element.shift,
              productionId: element.production.id,
            });
          });
        });
        productions.map((production, index) => {
          if (production.ewh == undefined || production.ewh == null) {
            production.ewh = 0;
          }
          production.rowId = index;
          return production;
        });

        state.effectiveWorkHourCoalDetail = action.payload;
        console.log('😒 | file: reducer.js:269 | action.payload:', action.payload);
        state.effectiveWorkHourCoalDetail.productions = productions;
        state.effectiveWorkHourCoalDetail.updatedAt = action.payload.updatedAt;
        state.effectiveWorkHourCoalDetail.statusLogList =
          action.payload.statusLogList;
        state.effectiveWorkHourCoalDetail.documentListEwh =
          action.payload.documentListEwh;
        state.effectiveWorkHourCoalDetail.coalGettingDetailResponseList.map(
          (dt) =>
            (state.effectiveWorkHourCoalDetail.productionId = dt.production.id)
        );
        state.effectiveWorkHourCoalDetail.coalGettingDetailResponseList.map(
          (production) => {
            if (production.shift == "Day") {
              state.effectiveWorkHourCoalDetail.dayShift = production;
            }
            if (production.shift == "Night") {
              state.effectiveWorkHourCoalDetail.nightShift = production;
            }
          }
        );

        if (
          state.effectiveWorkHourCoalDetail.dayShift.dataStatus === 3 &&
          state.effectiveWorkHourCoalDetail.nightShift.dataStatus === 3
        ) {
          state.effectiveWorkHourCoalDetail.disableSubmit = false;
        } else {
          state.effectiveWorkHourCoalDetail.disableSubmit = true;
        }

        state.getDetailData = true;
        state.effectiveWorkHourCoalDetail.loading = false;
      }
    );
    builder.addCase(
      getDetailEffectiveWorkHourCoal.rejected,
      (state, action) => {
        state.effectiveWorkHourCoalDetail.loading = false;
        state.error = "Invalid add data";
      }
    );
  },
});

export const effectiveWorkHourCoalSelector = (state) =>
  state.effectiveWorkHourCoal?.effectiveWorkHourCoal;
export const effectiveWorkHourDetailCoalSelector = (state) => {
  return state.effectiveWorkHourCoal?.effectiveWorkHourCoalDetail;
};

export const {
  getEffectiveWorkHourDetailCoal,
  onChangeProductionCoal,
  mapExcelData
} =
  effectiveWorkHourCoalSlice.actions;

export default effectiveWorkHourCoalSlice.reducer;
