import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { cloneDeep, isInteger, sum, sumBy } from "lodash";
import moment from "moment";
import { apiUrls, deleteRequest, get, patch, post, put } from "utils/request";
import { getUsername } from "utils/helper";

export const initialState = {
  effectiveWorkHour: {
    loading: false,
    records: [],
    totalRecords: "",
    error: "",
  },
  effectiveWorkHourDetail: {
    productions: [],
    productionId: null,
    dayShift: null,
    nightShift: null,
    updatedAt: null,
    disableSubmit: true,
    documentListEwh: [],
  },
};

export const getEffectiveWorkHour = createAsyncThunk(
  "effectiveWorkHour/get",
  async (params) => {
    const response = await post(
      `${apiUrls.wasteRemoval}/effective-work-hour/filter`,
      {
        columns: [
          {
            data: "reportId",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: params?.reportId || "",
            searchable: true,
          },
          {
            data: "createdAt",
            orderable: true,
            search: {
              regex: true,
              value: "",
            },
            searchValue: params.date || "",
            searchable: true,
          },
          {
            data: "pit",
            orderable: true,
            search: {
              regex: params?.pit ? true : false,
              value: "",
            },
            searchValue: params?.pit || "",
            searchable: true,
          },
          {
            data: "contractorData",
            orderable: true,
            search: {
              regex: params?.contractorName ? true : false,
              value: "",
            },
            searchValue: params?.contractorName || "",
            searchable: true,
          },
          {
            data: "dataStatus",
            orderable: true,
            search: {
              regex: params?.status ? true : false,
              value: "",
            },
            searchValue: params?.status || "",
            searchable: true,
          },
        ],
        search: {
          regex: false,
          value: params.query || "",
        },
        draw: 1,
        length: params?.length || 50,
      }
    );
    return response?.data;
  }
);

export const getDetailEffectiveWorkHour = createAsyncThunk(
  "effectiveWorkHour/detail",
  async (params) => {
    const response = await get(
      `${apiUrls.wasteRemoval}/effective-work-hour/${params}`
    );
    return response?.data;
  }
);

export const downloadFile = createAsyncThunk(
  'download-file',
  async (params) => {
    const { idReport, filename } = params;
    const response = await get(
      `${apiUrls.wasteRemoval}/effective-work-hour/${idReport}/files/${filename}`
    )
    return response.data;
  }
);

export const saveEffectiveWorkHour = createAsyncThunk(
  "effectiveWorkHour/save",
  async (params) => {
    const { idReport, payload } = params;

    const response = await post(
      `${apiUrls.wasteRemoval}/effective-work-hour/production/${
        idReport
      }/save?userName=${getUsername()}`,
      payload
    );
    return response?.data;
  }
);
export const submitEffectiveWorkHour = createAsyncThunk(
  "effectiveWorkHour/submit",
  async (params) => {
    const { idReport, payload } = params;
    const response = await post(
      `${apiUrls.wasteRemoval}/effective-work-hour/production/${idReport}/submit?userName=${getUsername()}`,
      payload
    );
    return response?.data;
  }
);

export const updateEffectiveWorkHourStatus = createAsyncThunk(
  "effectiveWorkHour/updateStatus",
  async (params) => {
    const response = await patch(
      `${apiUrls.wasteRemoval}/effective-work-hour/production/${params.id}/data-status?dataStatus=${params.status}`
    );
    return response?.data;
  }
);


export const updateStatusEffectiveWorkHourWaste = createAsyncThunk(
  "effectiveWorkHour/admin-action-waste",
  async (params) => {
    const { id, adminAction, notes } = params

    const response = await get(
      `${apiUrls.wasteRemoval}/effective-work-hour/${id}/${adminAction}?userName=${getUsername()}`, notes
    );
    return response?.data;
  }
);

export const intervensiEwhWaste = createAsyncThunk(
  `effectiveWorkHour/intervensi-waste`,
  async (params) => {
    let response

    try {
      response = await post(`${apiUrls.wasteRemoval}/intervensi/user-role/ewh/id`, params);
      return response?.data;
    }
    catch (err) {
      return err
    }
  }
);

const effectiveWorkHourSlice = createSlice({
  name: "WASTE_REMOVAL",
  initialState,
  reducers: {
    getEffectiveWorkHourDetail: (state, action) => {
      state.effectiveWorkHourDetail = action.payload;
    },
    onChangeProduction: (state, action) => {
      const { rowId, e } = action.payload;
      state.effectiveWorkHourDetail.productions.map((production, idx) => {
        if (Number(rowId) === idx) {
          production[e.target.name] = Number(e.target.value);
        }
        return production;
      });
    },
    mapExcelData: (state, action) => {
      const { activeTab, dataExcel } = action.payload;
      const productions = current(state.effectiveWorkHourDetail.productions)
      const filterProductions = productions?.filter((production) => production.status.job === activeTab)

      const result = filterProductions.map((item, index) => {
        const dataExcelItem = dataExcel[ index ];
        const { unit, volume, ewh, dailyProductivity } = dataExcelItem;
        return {
          ...item,
          id: {
            equipmentDetailId: unit
          },
          bcm: volume,
          ewh,
          productivity: dailyProductivity
        };
      });

      state.effectiveWorkHourDetail.productions = result;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getEffectiveWorkHour.pending, (state, action) => {
      state.loading = true;
      state.effectiveWorkHour.loading = true;
    });
    builder.addCase(getEffectiveWorkHour.fulfilled, (state, action) => {
      state.effectiveWorkHour.loading = false;
      state.effectiveWorkHour.totalRecords = action.payload.recordsTotal;
      state.effectiveWorkHour.records = action.payload.list;
    });
    builder.addCase(getEffectiveWorkHour.rejected, (state, action) => {
      state.loading = false;
      state.effectiveWorkHour.loading = false;
      state.error = "Invalid get data";
    });

    builder.addCase(saveEffectiveWorkHour.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(saveEffectiveWorkHour.fulfilled, (state, action) => {
      state.response = action.payload;
    });
    builder.addCase(saveEffectiveWorkHour.rejected, (state, action) => {
      state.loading = false;
      state.error = "Invalid get data";
    });

    builder.addCase(submitEffectiveWorkHour.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(submitEffectiveWorkHour.fulfilled, (state, action) => {
      state.response = action.payload;
    });
    builder.addCase(submitEffectiveWorkHour.rejected, (state, action) => {
      state.loading = false;
      state.error = "Invalid get data";
    });

    builder.addCase(getDetailEffectiveWorkHour.pending, (state, action) => {
      state.effectiveWorkHourDetail.loading = true;
    });
    builder.addCase(getDetailEffectiveWorkHour.fulfilled, (state, action) => {
      let productions = [];
      action.payload.wasteRemovalDetailResponseList.forEach((element) => {
        element?.production?.data?.forEach((production) => {
          productions.push({
            ...production,
            shift: element.shift,
            productionId: element.production.id,
          });
          productions.push({
            ...production,
            shift: element.shift,
            productionId: element.production.id,
          });
        });
      });
      productions.map((production, index) => {
        if (production.ewh == undefined || production.ewh == null) {
          production.ewh = 0;
        }
        production.rowId = index;
        return production;
      });

      state.effectiveWorkHourDetail = action.payload;
      state.effectiveWorkHourDetail.productions = productions;
      state.effectiveWorkHourDetail.updatedAt = action.payload.updatedAt;
      state.effectiveWorkHourDetail.statusLogList =
        action.payload.statusLogList;
      state.effectiveWorkHourDetail.documentListEwh =
        action.payload.documentListEwh;
      state.effectiveWorkHourDetail.wasteRemovalDetailResponseList.map(
        (dt) => (state.effectiveWorkHourDetail.productionId = dt.production.id)
      );
      state.effectiveWorkHourDetail.wasteRemovalDetailResponseList.map(
        (production) => {
          if (production.shift == "Day") {
            state.effectiveWorkHourDetail.dayShift = production;
          }
          if (production.shift == "Night") {
            state.effectiveWorkHourDetail.nightShift = production;
          }
        }
      );
      if (
        state.effectiveWorkHourDetail?.dayShift?.dataStatus === 3 &&
        state.effectiveWorkHourDetail?.nightShift?.dataStatus === 3
      ) {
        state.effectiveWorkHourDetail.disableSubmit = false;
      } else {
        state.effectiveWorkHourDetail.disableSubmit = true;
      }

      state.getDetailData = true;
      state.effectiveWorkHourDetail.loading = false;
    });
    builder.addCase(getDetailEffectiveWorkHour.rejected, (state, action) => {
      state.effectiveWorkHourDetail.loading = false;
      state.error = "Invalid add data";
    });
  },
});

export const effectiveWorkHourSelector = (state) =>
  state.effectiveWorkHour?.effectiveWorkHour;
export const effectiveWorkHourDetailSelector = (state) => {
  return state.effectiveWorkHour?.effectiveWorkHourDetail;
};

export const { getEffectiveWorkHourDetail, onChangeProduction, mapExcelData } =
  effectiveWorkHourSlice.actions;

export default effectiveWorkHourSlice.reducer;
