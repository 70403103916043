import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { cloneDeep, round, sum, sumBy } from 'lodash';
import moment from 'moment';
import { DATASOURCE_VALUE } from 'utils/helper';
import { apiUrls, get, post } from 'utils/request';

const formatData = [
  {
    code: "waste",
    header: "Waste Removal",
    unit: "",
    isTitle: true
  },
  {
    code: "waste",
    header: "Total Waste Removal",
    unit: "bcm",
    group: "itemTotalWasteRemovals",
    year0: 0,
  },
  {
    code: "waste",
    header: "Waste Removal Inpit",
    unit: "bcm",
    group: "itemsWasteRemovalInpit",
    isHeader: true,
    year0: 0,
  },
  {
    code: "waste",
    header: "Soil",
    unit: "bcm",
    group: "itemsWasteRemovalInpit",
    year0: 0,
  },
  {
    code: "waste",
    header: "Rehandle",
    unit: "bcm",
    group: "itemsWasteRemovalInpit",
    year0: 0,
  },
  {
    code: "waste",
    header: "OB Removal",
    unit: "bcm",
    group: "itemsWasteRemovalInpit",
    year0: 0,
  },
  {
    code: "waste",
    header: "OB Haul Distance",
    unit: "m",
    group: "itemsWasteRemovalInpit",
    year0: 0,
  },
  {
    code: "waste",
    header: "Waste Removal Outpit",
    unit: "bcm",
    group: "itemsWasteRemovalOutpit",
    isHeader: true,
    year0: 0,
  },
  {
    code: "waste",
    header: "Soil",
    unit: "bcm",
    group: "itemsWasteRemovalOutpit",
    year0: 0,
  },
  {
    code: "waste",
    header: "Rehandle",
    unit: "bcm",
    group: "itemsWasteRemovalOutpit",
    year0: 0,
  },
  {
    code: "waste",
    header: "OB Removal",
    unit: "bcm",
    group: "itemsWasteRemovalOutpit",
    year0: 0,
  },
  {
    code: "waste",
    header: "OB Haul Distance",
    unit: "m",
    group: "itemsWasteRemovalOutpit",
    year0: 0,
  },
  {
    code: "coal",
    header: "COAL",
    unit: "",
    isTitle: true,
    group: "itemsCoalExpose",
  },
  {
    code: "coal",
    header: "Coal Expose",
    unit: "ton",
    group: "itemsCoalExpose",
    isTitle: true,
    year0: 0,
  },
  {
    code: "coal",
    header: "SR Coal Expose",
    unit: "",
    group: "itemsCoalExpose",
    year0: 0,
  },
  {
    code: "coal",
    header: "Coal Getting",
    unit: "ton",
    group: "itemsCoalGetting",
    isTitle: true,
    year0: 0,
  },
  {
    code: "coal",
    header: "SR Coal Getting",
    unit: "",
    group: "itemsCoalGetting",
    year0: 0,
  },
  {
    code: "coal",
    header: "Coal Haul Distance (PIT to ROM)",
    unit: "m",
    group: "itemsCoalHaulDistance",
    year0: 0,
  },
  {
    code: "quality",
    header: "Quality",
    unit: "",
    isHeader: true,
    isTitle: true,
    group: "itemsQuality",
    year0: 0,
  },
  {
    code: "quality",
    header: "ASH",
    unit: "%",
    group: "itemsQuality",
    year0: 0,
  },
  {
    code: "quality",
    header: "CV_ADB",
    unit: "kcal/kg",
    group: "itemsQuality",
    year0: 0,
  },
  {
    code: "quality",
    header: "CV_GAR",
    unit: "kcal/kg",
    group: "itemsQuality",
    year0: 0,
  },
  {
    code: "quality",
    header: "FC",
    unit: "%",
    group: "itemsQuality",
    year0: 0,
  },
  {
    code: "quality",
    header: "IM",
    unit: "%",
    group: "itemsQuality",
    year0: 0,
  },
  {
    code: "quality",
    header: "RD_IS",
    unit: "ton/bcm",
    group: "itemsQuality",
    year0: 0,
  },
  {
    code: "quality",
    header: "TM",
    unit: "%",
    group: "itemsQuality",
    year0: 0,
  },
  {
    code: "quality",
    header: "TS",
    unit: "%",
    group: "itemsQuality",
    year0: 0,
  },
  {
    code: "quality",
    header: "VM",
    unit: "%",
    group: "itemsQuality",
    year0: 0,
  }
];


export const initialState = {
  longTermPlanning: {
    loading: false,
    records: [],
    recordsTotal: '',
    error: '',
  },
  longTermPlanningDetail: {
    loading: false,
    records: [],
    error: '',
    version: 1,
    listOption: [
      {
        title: "Option 1",
        nav: "option1",
      }
    ],
    defaultData: [
      {
        option: 'option1',
        firstYear: moment().format('Y'),
        listYear: [ moment().format('Y') ],
        data: formatData
      }
    ],
    // data: {
    //   dataInpit: [
    //     { wasteInpit: 'Soil', unit: 'bcm' },
    //     { wasteInpit: 'Rehandle', unit: 'bcm' },
    //     { wasteInpit: 'OB Removal', unit: 'bcm' },
    //     { wasteInpit: 'OB Haul Distance', unit: 'm' },
    //   ],
    //   dataOutpit: [
    //     { wasteOutpit: 'Soil', unit: 'bcm' },
    //     { wasteOutpit: 'Rehandle', unit: 'bcm' },
    //     { wasteOutpit: 'OB Removal', unit: 'bcm' },
    //     { wasteOutpit: 'OB Haul Distance', unit: 'm' },
    //   ],
    //   dataWasteCoal: [{ wasteCoal: 'Coal Getting', unit: '' }],
    //   dataWasteCoalExpose: [{ wasteCoalExpose: 'Coal Expose', unit: '' }],
    //   dataWasteCoalHaul: [
    //     { wasteCoalHaul: 'Coal Haul Distance (PIT to ROM)', unit: 'm' },
    //   ],
    //   dataWasteQuality: [
    //     { wasteQuality: 'ASH', unit: '%' },
    //     { wasteQuality: 'CV_ADB', unit: 'kcal/kg' },
    //     { wasteQuality: 'CV_GAR', unit: 'kcal/kg' },
    //     { wasteQuality: 'FC', unit: '%' },
    //     { wasteQuality: 'IM', unit: '%' },
    //     { wasteQuality: 'RD_IS', unit: 'ton/bcm' },
    //     { wasteQuality: 'TM', unit: '%' },
    //     { wasteQuality: 'TS', unit: '%' },
    //     { wasteQuality: 'VM', unit: '%' },
    //   ],
    // },
  },
  longTermPlanningDetailColumn: {
    // dataHeaderTotalWasteRemoval: [],
    // dataHeader: [],
    // dataInpit: [],
    // dataOutpit: [],
    // dataWasteCoal: [],
    // dataWasteCoalExpose: [],
    // dataWasteCoalHaul: [],
    // dataWasteQuality: [],
    defaultColumn: [],
  },
  versionList: {
    loading: false,
    versionList: [ 1 ],
    error: '',
  },
  currentObject: {},
  isReload: null,
};

export const getLongTermPlanning = createAsyncThunk(
  'longTermPlanning/get',
  async (params) => {
    const response = await post(`${apiUrls.longTermPlannings}/filter`, {
      columns: [
        {
          data: 'reportId',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: params.reportId || '',
          searchable: true,
        },
        {
          data: 'createdAt',
          orderable: true,
          search: {
            regex: true,
            value: '',
          },
          searchValue: params.date || '',
          searchable: true,
        },
        {
          data: 'year',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: params.year || '',
          searchable: true,
        },
        {
          data: 'version',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: params.version || '',
          searchable: true,
        },
        {
          data: 'contractor',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: params.contractor || '',
          searchable: true,
        },
        {
          data: 'dataStatus',
          orderable: true,
          search: {
            regex: params.status === DATASOURCE_VALUE.waitingApproval || false,
            value: '',
          },
          searchValue: params.status || '',
          searchable: true,
        },
      ],
      order: [],
      search: {
        regex: true,
        value: params.query || '',
      },
      draw: params?.pageIndex ? params.pageIndex : 1,
      length: params.dataLength ? params.dataLength : -1,
    });
    return response?.data;
  }
);

export const getLongTermPlanningDetail = createAsyncThunk(
  'longTermPlanning/detail',
  async (id) => {
    const response = await get(`${apiUrls.longTermPlannings}/${id}`);
    return response?.data;
  }
);

export const getVersionList = createAsyncThunk(
  'longTermPlanning/versionlist',
  async (params) => {
    const response = await get(
      `${apiUrls.longTermPlannings}/${params}/versions`
    );
    return response?.data;
  }
);

export const getByVersion = createAsyncThunk(
  'longTermPlanning/getByVersion',
  async (params) => {
    const response = await get(
      `${apiUrls.longTermPlannings}/${params.reportId}/versions/${params.version}`
    );
    return response?.data;
  }
);

export const intervensiLongTermPlanning = createAsyncThunk(
  'longTermPlanning/intervensi',
  async (params) => {
    let response;

    try {
      response = await post(`${apiUrls.intervensiMinePlan}/user-role?moduleName=LongTermPlan`, params);
      return response?.data;
    }
    catch (err) {
      return err;
    }
  }
);

const LONG_TERM_PLANNING = 'LONG_TERM_PLANNING';

const getValuesByHeader = (temp) => {
  const result = {};

  if (temp.length === 0) {
    return result;
  }


  for (const item of temp) {
    const header = item.header;
    const group = item.group;
    const code = item.code;
    const unit = item.unit;
    const values = Object.values(item).filter((i) => typeof i === "number");

    if (!result[ group ]) {
      result[ group ] = {};
    }

    if (!result[ group ][ header ]) {
      result[ group ][ header ] = [];
    }

    // result[ group ][ header ].push({ values, group, code, unit });
    result[ group ][ header ].push(...values);
  }

  return result;
};

const sumArrays = (arrays) => {
  const result = [];
  const maxLength = Math.max(...arrays.map(arr => arr.length));

  for (let i = 0; i < maxLength; i++) {
    let sum = 0;
    for (let j = 0; j < arrays.length; j++) {
      sum += arrays[ j ][ i ] || 0;
    }
    result.push(sum);
  }

  return result;
};

const subtractArrays = (arr1, arr2) => {
  // if (arr1.length !== arr2.length) {
  //   throw new Error('Arrays must have the same length');
  // }

  const result = [];

  for (let i = 0; i < arr1.length; i++) {
    result.push(arr1[ i ] - arr2[ i ]);
  }

  return result;
};

const divideArrays = (arr1, arr2) => {
  // if (arr1.length !== arr2.length) {
  //   throw new Error('Arrays must have the same length');
  // }

  const result = [];

  for (let i = 0; i < arr1.length; i++) {
    result.push(arr1[ i ] / arr2[ i ]);
  }

  return result;
};

const longTermPlanning = createSlice({
  name: LONG_TERM_PLANNING,
  initialState,
  reducers: {
    clearlongTermPlanningDetail: (state, action) => {
      state.longTermPlanningDetail = {
        loading: false,
        records: [],
        error: '',
        listOption: [
          {
            title: "Option 1",
            nav: "option1",
          }
        ],
        defaultData: [
          {
            option: 'option1',
            firstYear: moment().format('Y'),
            listYear: [ moment().format('Y') ],
            data: formatData
          }
        ],
      };
    },

    onChangeTableWasteInpit: (state, action) => {
      const payload = action.payload;
      let val = parseInt(payload.value, 10);
      // allow to sent decimal
      if (payload.value?.includes('.'))
        val = payload.value.split('.').join(',');
      state.longTermPlanningDetail.data[ payload.tableType ][
        payload.tableIndexRow
      ][ payload.tableRowName ] = val;
    },
    createColumnLongPlanningDetail: (state, action) => {
      const payload = action.payload;
      if (payload.tableName)
        state.longTermPlanningDetailColumn[ payload.tableName ] = payload.data;

      if (payload.undoColumn) state.longTermPlanningDetailColumn = payload.data;
    },
    onChangeCounterTableHeader: (state, action) => {
      const payload = action.payload;

      let counterHeader = 0;

      const dataTableDetail = current(
        state.longTermPlanningDetail.data[ payload.tableType ]
      );

      /**
       * Todo: mapping data detail for create counter header
       */
      dataTableDetail.map((data, index) => {
        if (data[ payload.tableRowName ]) {
          counterHeader +=
            data.unit !== 'm' ? parseInt(data[ payload.tableRowName ]) : 0;
        }
      });

      /**
       * Todo: get detail table for column
       */
      const dataTableColumn = current(
        state.longTermPlanningDetailColumn[ payload.tableType ]
      );

      /**
       * Todo: get index table detail column
       */
      const findIndex = dataTableColumn.findIndex(
        (x) => x.accessor == payload.tableRowName
      );
      // const findIndexMonth1 = dataTableColumn.findIndex((x) => x.accessor == 'year1');

      /**
       * Todo: parsing counter data header table column
       */
      if (payload.tableType !== 'dataWasteQuality')
        state.longTermPlanningDetailColumn[ payload.tableType ][ findIndex ][
          'Header'
        ] = counterHeader;

      const isWasteRemoval =
        payload.tableType === 'dataInpit' || payload.tableType === 'dataOutpit';

      const { data: detailData } = current(state.longTermPlanningDetail);
      const {
        dataInpit: wasteRemovalInpitColumns,
        dataOutpit: wasteRemovalOutpitColumns,
      } = current(state.longTermPlanningDetailColumn);

      // set formula per column SR Coal Getting
      if (
        payload.tableType === 'dataWasteCoal' ||
        payload.tableType === 'dataInpit'
      ) {
        const val =
          payload.tableType === 'dataWasteCoal'
            ? +payload.value
            : +(detailData.dataWasteCoal?.[ 0 ]?.[ payload.tableRowName ] || 0);
        const totalWasteRemoval =
          (wasteRemovalInpitColumns.find(
            (i) => i.accessor === payload.tableRowName
          )?.Header ?? 0) +
          (wasteRemovalOutpitColumns.find(
            (i) => i.accessor === payload.tableRowName
          )?.Header ?? 0);

        const calc = totalWasteRemoval / val;
        state.longTermPlanningDetailColumn[ 'dataWasteCoal' ][ findIndex ][
          'Header'
        ] = val === 0 ? 0 : round(calc, 2);
      }

      // set formula per column SR Coal Expose
      if (
        payload.tableType === 'dataWasteCoalExpose' ||
        payload.tableType === 'dataOutpit'
      ) {
        const val =
          payload.tableType === 'dataWasteCoalExpose'
            ? +payload.value
            : +(
              detailData.dataWasteCoalExpose?.[ 0 ]?.[ payload.tableRowName ] || 0
            );
        const totalWasteRemoval =
          (wasteRemovalInpitColumns.find(
            (i) => i.accessor === payload.tableRowName
          )?.Header ?? 0) +
          (wasteRemovalOutpitColumns.find(
            (i) => i.accessor === payload.tableRowName
          )?.Header ?? 0);

        const calc = totalWasteRemoval / val;
        state.longTermPlanningDetailColumn[ 'dataWasteCoalExpose' ][ findIndex ][
          'Header'
        ] = val === 0 ? 0 : round(calc, 2);
      }

      // set total waste removal header
      if (isWasteRemoval) {
        console.log('😒 | file: reducer.js:409 | isWasteRemoval:', isWasteRemoval);
        const totalWasteRemoval =
          (wasteRemovalInpitColumns.find(
            (i) => i.accessor === payload.tableRowName
          )?.Header ?? 0) +
          (wasteRemovalOutpitColumns.find(
            (i) => i.accessor === payload.tableRowName
          )?.Header ?? 0);
        state.longTermPlanningDetailColumn[ 'dataHeaderTotalWasteRemoval' ][
          findIndex
        ][ 'Header' ] = totalWasteRemoval;
      }
    },

    createColumnPlanningDetail: (state, action) => {
      const payload = action.payload;
      state.longTermPlanningDetailColumn[ payload.tableName ] = payload.data;
    },

    addColumnOption: (state, action) => {
      const dataLength = state.longTermPlanningDetail.defaultData.length;
      let newData = {
        option: `option${dataLength + 1}`,
        firstYear: moment().format('Y'),
        listYear: [ moment().format('Y') ],
        data: formatData
      };
      let listOptions = current(state.longTermPlanningDetail.listOption) || [];
      const newOption = {
        title: "Option " + (dataLength + 1),
        nav: "option" + (dataLength + 1)
      };
      state.longTermPlanningDetail.listOption = [ ...listOptions, newOption ];

      // state.longTermPlanningDetail.listOption.push({
      //   title: "Option "+(dataLength+1),
      //   nav: "option"+(dataLength+1)
      // });

      // state.longTermPlanningDetail.defaultData.push(newData);
      let listDefaultData = current(state.longTermPlanningDetail.defaultData) || [];
      state.longTermPlanningDetail.defaultData = [ ...listDefaultData, newData ];
    },

    deleteColumnOption: (state, action) => {
      const dataLength = state.longTermPlanningDetail.listOption.length;
      if (dataLength > 1) {
        state.longTermPlanningDetail.listOption.pop();
        state.longTermPlanningDetail.defaultData.pop();
      }
    },

    addColumnYear: (state, action) => {
      const payload = action.payload;
      let newData = state.longTermPlanningDetail.defaultData.map(dt => {
        if (dt.option === payload.tab) {
          const lengthYear = dt.listYear.length;
          dt.listYear.push(parseInt(dt.listYear[ lengthYear - 1 ]) + 1);
        }
        return dt;
      });
      state.longTermPlanningDetail.defaultData = newData;
    },

    deleteColumnYear: (state, action) => {
      const payload = action.payload;
      let newData = state.longTermPlanningDetail.defaultData.map(dt => {
        let nd = { ...dt };
        if (dt.option === payload.tab) {
          const lengthYear = dt.listYear.length;
          if (lengthYear > 1) {
            nd.listYear.pop();
            nd.data.map(el => {
              let nde = { ...el };
              delete nde[ 'year' + (lengthYear - 1) ];
              return nde;
            });
          }
        }
        return nd;
      });
      state.longTermPlanningDetail.defaultData = newData;
    },

    onChangeFirstYear: (state, action) => {
      const payload = action.payload;
      let newData = state.longTermPlanningDetail.defaultData.map(dt => {
        let nd = { ...dt };
        if (dt.option === payload.tab) {
          nd.firstYear = payload.year;
          let counter = 1;
          let tempListYear = dt.listYear.map((year, idx) => {
            let ny = { ...year };
            if (idx === 0) {
              ny = parseInt(payload.year);
            } else {
              ny = parseInt(payload.year) + counter;
              counter++;
            }
            return ny;
          });
          nd.listYear = tempListYear;
        }
        return nd;
      });
      state.longTermPlanningDetail.defaultData = newData;
    },

    onChangeDataTable: (state, action) => {
      const payload = action.payload;
      const original = state.longTermPlanningDetail.defaultData;
      const { data, header, tab, tableIndexRow, tableRowName, tableType, value, group } = payload;

      const isValueFloat = value?.includes('.');
      const tempValue = isValueFloat ? parseFloat(payload.value) : parseInt(payload.value, 10);

      const tempData = data.map((item, idx) => {
        const tempItem = { ...item };
        const isSameRow = idx === tableIndexRow;
        const isSameHeader = item.header === header;
        const isSameType = item.code === tableType;
        const isSameGroup = item.group === group;

        if (isSameRow && isSameHeader && isSameType && isSameGroup) {
          tempItem[ tableRowName ] = tempValue || 0;
        }

        return tempItem;
      });


      const dataByGroup = getValuesByHeader(tempData);
      const itemsWasteRemovalInpit = dataByGroup?.itemsWasteRemovalInpit;
      const itemsWasteRemovalInpitSoil = itemsWasteRemovalInpit[ "Soil" ]?.flat();
      const itemsWasteRemovalInpitRehandle = itemsWasteRemovalInpit[ "Rehandle" ]?.flat();
      const itemsWasteRemovalInpitOBRemoval = itemsWasteRemovalInpit[ "OB Removal" ]?.flat();
      const itemsWasteRemovalInpitSum = sumArrays([
        itemsWasteRemovalInpitSoil,
        itemsWasteRemovalInpitRehandle,
        itemsWasteRemovalInpitOBRemoval
      ]);
      const itemsWasteRemovalInpitSumByYear = itemsWasteRemovalInpitSum.reduce((acc, cur, idx) => {
        acc[ `year${idx}` ] = cur;
        return acc;
      }, {});


      const itemsWasteRemovalOutpit = dataByGroup?.itemsWasteRemovalOutpit;
      const itemsWasteRemovalOutpitSoil = itemsWasteRemovalOutpit[ "Soil" ]?.flat();
      const itemsWasteRemovalOutpitRehandle = itemsWasteRemovalOutpit[ "Rehandle" ]?.flat();
      const itemsWasteRemovalOutpitOBRemoval = itemsWasteRemovalOutpit[ "OB Removal" ]?.flat();
      const itemsWasteRemovalOutpitSum = sumArrays([
        itemsWasteRemovalOutpitSoil,
        itemsWasteRemovalOutpitRehandle,
        itemsWasteRemovalOutpitOBRemoval
      ]);
      const itemsWasteRemovalOutpitSumByYear = itemsWasteRemovalOutpitSum.reduce((acc, cur, idx) => {
        acc[ `year${idx}` ] = cur;
        return acc;
      }, {});


      const itemsWasteRemovalSum = sumArrays([
        itemsWasteRemovalInpitSum,
        itemsWasteRemovalOutpitSum
      ]);
      const itemsWasteRemovalSumByYear = itemsWasteRemovalSum.reduce((acc, cur, idx) => {
        acc[ `year${idx}` ] = cur;
        return acc;
      }, {});


      const itemsCoalExpose = dataByGroup?.itemsCoalExpose;
      const itemsCoalExposeCoalExpose = itemsCoalExpose[ "Coal Expose" ]?.flat();
      const itemsCoalExposeCoalExposeDivide = divideArrays(itemsWasteRemovalSum, itemsCoalExposeCoalExpose);
      const itemsSRCoalExposeCoalExposeDivideByYear = itemsCoalExposeCoalExposeDivide.reduce((acc, cur, idx) => {
        acc[ `year${idx}` ] = cur;
        return acc;
      }, {});


      const itemsCoalGetting = dataByGroup?.itemsCoalGetting;
      const itemsCoalGettingCoalGetting = itemsCoalGetting[ "Coal Getting" ]?.flat();
      const itemsCoalGettingCoalGettingDivide = divideArrays(itemsWasteRemovalSum, itemsCoalGettingCoalGetting);
      const itemsSRCoalGettingCoalGettingDivideByYear = itemsCoalGettingCoalGettingDivide.reduce((acc, cur, idx) => {
        acc[ `year${idx}` ] = cur;
        return acc;
      }, {});

      const autoCalcDataHeader = {
        'Waste Removal Inpit': itemsWasteRemovalInpitSumByYear,
        'Waste Removal Outpit': itemsWasteRemovalOutpitSumByYear,
        'Total Waste Removal': itemsWasteRemovalSumByYear,
        'SR Coal Expose': itemsSRCoalExposeCoalExposeDivideByYear,
        'SR Coal Getting': itemsSRCoalGettingCoalGettingDivideByYear,
      };

      const listAutoCalcDataHeader = Object.keys(autoCalcDataHeader);
      const tempDataReplace = tempData.map((item, idx) => {
        let tempItem = { ...item };
        const isSameHeader = listAutoCalcDataHeader.includes(item.header);
        if (isSameHeader) {
          tempItem = { ...tempItem, ...autoCalcDataHeader[ item.header ] };
        }
        return tempItem;
      });


      const tempDefaultData = original.map((dt, idx) => {
        let nd = { ...dt };
        const isSameTab = dt.option === tab;
        if (isSameTab) {
          nd.data = tempDataReplace;
        }
        return nd;
      });

      state.longTermPlanningDetail.defaultData = tempDefaultData;

    },

    onReplaceData: (state, action) => {
      const ltpData = action.payload.ltpData;
      const listOption = action.payload.listOption;
      state.longTermPlanningDetail.listOption = listOption;
      state.longTermPlanningDetail.defaultData = ltpData;
    },

    counterDataTable: (state, action) => {
      const payload = action.payload;

      let newData = state.longTermPlanningDetail.defaultData.map(dt => {
        let nd = { ...dt };
        if (dt.option === payload.tab) {
          let dtWasteInpit = dt.data.filter(el => el.code && el.type === "inpit" && !el.isHeader && el.unit !== "m");
          let countHeaderInpit = sumBy(dtWasteInpit, function (o) { return parseFloat(o[ payload.tableRowName ]?.toString().replace(",", ".") || 0); });
          nd.data[ 2 ][ payload.tableRowName ] = countHeaderInpit || 0;

          let dtWasteOutpit = dt.data.filter(el => el.code && el.type === "outpit" && !el.isHeader && el.unit !== "m");
          let countHeaderOutpit = sumBy(dtWasteOutpit, function (o) { return parseFloat(o[ payload.tableRowName ]?.toString().replace(",", ".") || 0); });
          nd.data[ 7 ][ payload.tableRowName ] = countHeaderOutpit || 0;

          let countHeaderWaste = (countHeaderInpit + countHeaderOutpit);
          nd.data[ 1 ][ payload.tableRowName ] = countHeaderWaste || 0;

          let coalExpose = dt.data[ 13 ];
          let countSrExpose = countHeaderWaste / coalExpose[ payload.tableRowName ];
          nd.data[ 14 ][ payload.tableRowName ] = countSrExpose || 0;

          let coalGetting = dt.data[ 15 ];
          let countSrGetting = countHeaderWaste / coalGetting[ payload.tableRowName ];
          nd.data[ 16 ][ payload.tableRowName ] = countSrGetting || 0;
        }
        return nd;
      });

      state.longTermPlanningDetail.defaultData = newData;
    },

    onReload: (state, action) => {
      const payload = action.payload;
      state.isReload = payload;
    }

  },
  extraReducers: (builder) => {
    builder.addCase(getLongTermPlanning.pending, (state, action) => {
      state.longTermPlanning.loading = true;
    });
    builder.addCase(getLongTermPlanning.fulfilled, (state, action) => {
      state.longTermPlanning.loading = false;
      state.longTermPlanning.records = action.payload.list;
      state.longTermPlanning.recordsTotal = action.payload.recordsTotal;
    });
    builder.addCase(getLongTermPlanning.rejected, (state, action) => {
      state.longTermPlanning.loading = false;
      state.longTermPlanning.error = 'Invalid get data';
    });

    builder.addCase(getLongTermPlanningDetail.pending, (state, action) => {
      state.longTermPlanningDetail.loading = true;
    });
    builder.addCase(getLongTermPlanningDetail.fulfilled, (state, action) => {
      let dataLtp = { ...action.payload, defaultData: action.payload?.data?.ltpData || [], listOption: action.payload?.data?.listOption || [] };
      state.longTermPlanningDetail = dataLtp;
    });
    builder.addCase(getLongTermPlanningDetail.rejected, (state, action) => {
      state.longTermPlanningDetail.loading = false;
      state.longTermPlanningDetail.error = 'Invalid get data';
    });

    builder.addCase(getVersionList.pending, (state, action) => {
      state.versionList.loading = true;
    });
    builder.addCase(getVersionList.fulfilled, (state, action) => {
      state.versionList.versionList = action.payload;
    });
    builder.addCase(getVersionList.rejected, (state, action) => {
      state.versionList.loading = false;
      state.versionList.error = 'Invalid get data';
    });

    builder.addCase(getByVersion.pending, (state, action) => {
      state.longTermPlanningDetail.loading = true;
    });
    builder.addCase(getByVersion.fulfilled, (state, action) => {
      state.longTermPlanningDetail.versionList = action.payload;
    });
    builder.addCase(getByVersion.rejected, (state, action) => {
      state.longTermPlanningDetail.loading = false;
      state.longTermPlanningDetail.error = 'Invalid get data';
    });
  },
});

export const {
  clearlongTermPlanningDetail,
  onChangeTableWasteInpit,
  createColumnLongPlanningDetail,
  onChangeCounterTableHeader,
  createColumnPlanningDetail,
  addColumnYear,
  deleteColumnYear,
  onChangeFirstYear,
  addColumnOption,
  deleteColumnOption,
  onChangeDataTable,
  counterDataTable,
  onReload,
  onReplaceData,
} = longTermPlanning.actions;

export const longTermPlanningSelector = (state) =>
  state.longTermPlanning.longTermPlanning;
export const longTermPlanningDetailSelector = (state) =>
  state.longTermPlanning.longTermPlanningDetail;
export const longTermPlanningDetailColumn = (state) =>
  state.longTermPlanning.longTermPlanningDetailColumn;
export const versionListSelector = (state) =>
  state.longTermPlanning.versionList;
export const isReloadSelector = (state) =>
  state.longTermPlanning.isReload;

export default longTermPlanning.reducer;
